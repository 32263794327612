import { useState } from 'react';
import styles from './EditRevenueModal.module.css';
import MainSpinner from '../Spinners/MainSpinner';
import ErrorModal from '../Error/ErrorModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { ApiResponse } from '../../interfaces';

function EditRevenueModal(props: { close: () => any; dateISOString: string; prevAmount: number; }) {
    const [amount, setAmount] = useState('');
    const [isAmountValid, setIsAmountValid] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [shouldReload, setShouldReload] = useState(false);

    const accessToken = useSelector((state: RootState) => state.auth.accessToken);
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const env = process.env.NODE_ENV || 'development';

    let baseUrl = "";

    if (env != 'development') {
        baseUrl = process.env.REACT_APP_BACKEND_URL!;
    }

    const apiUrl = `${baseUrl}/api/revenue/editRevenue`;

    function amountInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        setAmount(event.target.value);
    };

    function amountValidation(value: string) {
        const regex = /^(?:[0-9]{1,7}(?:\.[0-9]{1,2})?)$/;
        return regex.test(value);
    };

    async function handleSubmit() {
        setIsAmountValid(true);

        const isValidAmount = amountValidation(amount);

        if (!isValidAmount) {
            setIsAmountValid(false);
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                body: JSON.stringify({
                    date: props.dateISOString,
                    amount: amount,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken,
                }
            })

            const contentType = response.headers.get('Content-Type');
            if (!contentType || !contentType.includes('application/json')) throw new Error("Unexpected error occured.");

            const data: ApiResponse<void> = await response.json();

            if (!response.ok) {
                if (response.status == 400 || response.status == 401) setShouldReload(true);
                throw new Error(data.message);
            }

            setIsSuccess(true);
            window.location.reload();
        }
        catch (err: unknown) {
            if (err instanceof Error) setError(err.message);
            else setError("An unexpected error occurred, please try again.");
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.editRevenueBackdrop} onMouseDown={props.close}>
            <div className={styles.editRevenue} onMouseDown={(e) => e.stopPropagation()}>
                <p className={styles.title}>{`Edit Revenue For: ${props.dateISOString}`}</p>
                <div className={styles.body}>
                    <p className={styles.prevAmount}>{`Current Amount: ${formatter.format(props.prevAmount)}`}</p>
                    <div className={`${styles.inputContainer} ${styles.noMargin}`}>
                        <div className={styles.inputContainerMain}>
                            <i className={`${styles.inputContainerIcon} fa-solid fa-dollar-sign`}></i>
                            <input
                                className={`${styles.input} ${styles.inputPadding}`}
                                placeholder="New Amount (no commas)"
                                value={amount}
                                onChange={amountInputHandler}
                                required
                            />
                        </div>
                        {!isAmountValid && <p className={styles.error}>Please enter a valid amount. 0 - 9999999.99</p>}
                    </div>
                </div>
                <button className={styles.button} style={{backgroundColor: isSuccess ? "var(--green)" : isSuccess == false ? "var(--red)" : "var(--blue)"}} onClick={async() => await handleSubmit()}>
                    {isSuccess ? <i className="fa-solid fa-check" style={{color: "white", fontSize: 18}}/> : "Save"}
                </button>
                {isLoading && <MainSpinner />}
                {error && <ErrorModal message={error} action={!shouldReload ? () => null : () => window.location.reload()} closeModal={() => setError(null)} />}
            </div>
        </div>
    )
}

export default EditRevenueModal;
