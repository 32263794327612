import { Dispatch } from "@reduxjs/toolkit";
import { login } from "../../Redux/slices/authSlice";
import { ApiResponse, CustomErrorInfo, MainUserDataResponse } from "../../interfaces";
import { SetStateAction } from "react";
import { logoutMain } from "./logout";

export async function fetchMainUserData(dispatch: Dispatch, setError: React.Dispatch<SetStateAction<CustomErrorInfo | null>>, setIsLoading: React.Dispatch<SetStateAction<boolean>>, setAuthError: React.Dispatch<SetStateAction<boolean>>): Promise<void> {
    const env = process.env.NODE_ENV || 'development';

    let baseUrl = "";

    if (env != 'development') {
        baseUrl = process.env.REACT_APP_BACKEND_URL!;
    }

    const apiUrl = `${baseUrl}/api/user/fetchMainUserData`;

    try {
        const response = await fetch(apiUrl, {
            method: "GET",
            credentials: "include",
        });

        const contentType = response.headers.get('Content-Type');
        if (!contentType || !contentType.includes('application/json')) {
            setAuthError(false);
            throw new Error("Unexpected error occured.");
        }

        const data: ApiResponse<MainUserDataResponse> = await response.json();
        if (!response.ok) {
            if (response.status == 400 || response.status == 401) setAuthError(true);
            else setAuthError(false);

            throw new Error("Your session has expired, please log back in to continue.");
        }

        dispatch(login(data.data));
    }
    catch (err: any) {
        const error: CustomErrorInfo = {
            message: err.message,
            statusCode: 400,
            action: () => logoutMain(dispatch),
        }
        setError(error);
    }
    finally {
        setIsLoading(false);
    }
}
