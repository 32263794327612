import { useState } from 'react';
import styles from './AddRevenueModal.module.css';
import MainSpinner from '../Spinners/MainSpinner';
import ErrorModal from '../Error/ErrorModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { ApiResponse } from '../../interfaces';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';

function AddRevenueModal(props: { close: () => any; }) {
    const [date, setDate] = useState('');
    const [amount, setAmount] = useState('');
    const [isDateValid, setIsDateValid] = useState(true);
    const [isAmountValid, setIsAmountValid] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [shouldReload, setShouldReload] = useState(false);

    const today = new Date().toISOString().split("T")[0];

    const accessToken = useSelector((state: RootState) => state.auth.accessToken);

    const env = process.env.NODE_ENV || 'development';

    let baseUrl = "";

    if (env != 'development') {
        baseUrl = process.env.REACT_APP_BACKEND_URL!;
    }

    const apiUrl = `${baseUrl}/api/revenue/addRevenue`;

    function amountInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        setAmount(event.target.value);
    };

    function dateInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const inputDate = event.target.value;
        const dateAsUTC = parseISO(inputDate);
        setDate(format(dateAsUTC, 'yyyy-MM-dd'));
    }

    function amountValidation(value: string) {
        const regex = /^(?:[0-9]{1,7}(?:\.[0-9]{1,2})?)$/;
        return regex.test(value);
    };

    async function handleSubmit() {
        setIsAmountValid(true);
        setIsDateValid(true);

        const isValidAmount = amountValidation(amount);
        const isValidDate = date != "";

        if (!isValidAmount) setIsAmountValid(false);
        if (!isValidDate) setIsDateValid(false);
        if (!isValidAmount || !isValidDate) return;

        setIsLoading(true);

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                body: JSON.stringify({
                    date: date,
                    amount: amount,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken,
                }
            })

            const contentType = response.headers.get('Content-Type');
            if (!contentType || !contentType.includes('application/json')) throw new Error("Unexpected error occured.");

            const data: ApiResponse<void> = await response.json();

            if (!response.ok) {
                if (response.status == 400 || response.status == 401) setShouldReload(true);
                throw new Error(data.message);
            }

            setIsSuccess(true);
            window.location.reload();
        }
        catch (err: unknown) {
            if (err instanceof Error) setError(err.message);
            else setError("An unexpected error occurred, please try again.");
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.addRevenueBackdrop} onMouseDown={props.close}>
            <div className={styles.addRevenue} onMouseDown={(e) => e.stopPropagation()}>
                <p className={styles.title}>Add Daily Revenue</p>
                <div className={styles.body}>
                    <div className={styles.inputContainer}>
                        <input className={styles.input} type="date" value={date} onChange={dateInputHandler} max={today} required />
                        {!isDateValid && <p className={styles.error}>Please enter a date.</p>}
                    </div>
                    <div className={`${styles.inputContainer} ${styles.noMargin}`}>
                        <div className={styles.inputContainerMain}>
                            <i className={`${styles.inputContainerIcon} fa-solid fa-dollar-sign`}></i>
                            <input
                                className={`${styles.input} ${styles.inputPadding}`}
                                placeholder="Amount (no commas)"
                                value={amount}
                                onChange={amountInputHandler}
                                required
                            />
                        </div>
                        {!isAmountValid && <p className={styles.error}>Please enter a valid amount. 0 - 9999999.99</p>}
                    </div>
                </div>
                <button className={styles.button} style={{backgroundColor: isSuccess ? "var(--green)" : isSuccess == false ? "var(--red)" : "var(--blue)"}}onClick={async() => await handleSubmit()}>
                    {isSuccess ? <i className="fa-solid fa-check" style={{color: "white", fontSize: 18}}/> : "Add"}
                </button>
                {isLoading && <MainSpinner />}
                {error && <ErrorModal message={error} action={!shouldReload ? () => null : () => window.location.reload()} closeModal={() => setError(null)} />}
            </div>
        </div>
    )
}

export default AddRevenueModal;
