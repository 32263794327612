import { Fragment, useState } from 'react';
import styles from './Header.module.css';
import { logoutMain } from './Api/logout';
import { useDispatch, useSelector } from 'react-redux';
import AddRevenueModal from './Modals/AddRevenueModal';
import { RootState } from '../Redux/store';
import Settings from './Modals/Settings';
import { useLocation, useNavigate } from 'react-router-dom';

function Header() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAddRevenueModal, setShowAddRevenueModal] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    const name = useSelector((state: RootState) => state.auth.userData?.businessName);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const homeGlow = location.pathname.startsWith("/home") ? styles.glow : '';
    const rawDataGlow = location.pathname.startsWith("/rawData") ? styles.glow : '';

    return (
        <div className={styles.header}>
            <div className={styles.headerLeft}>
                <p className={styles.logoText}>Ascend X</p>
                <button className={`${styles.navOption} ${homeGlow}`} onClick={() => navigate("/home")}>Home</button>
                <button className={`${styles.navOption} ${styles.noMarginRight} ${rawDataGlow}`} onClick={() => navigate("/rawData")}>Raw Data</button>
            </div>
            <div className={styles.headerRight}>
                <i className={`${styles.headerRightIcon} ${styles.blue} fa-solid fa-plus`} onClick={() => setShowAddRevenueModal(true)}/>
                <i className={`${styles.headerRightIcon} fa-solid fa-gear`} onClick={() => setShowSettings(true)}/>
                {/* <i className={`${styles.headerRightIcon} fa-solid fa-bell`} /> */}
                <button className={styles.username} onClick={() => setShowDropdown(!showDropdown)}>{name ? name.charAt(0).toUpperCase() + name.slice(1) : ""}</button>
                {showDropdown &&
                    <Fragment>
                        <div className={styles.dropdownBackdrop} onClick={() => setShowDropdown(false)}/>
                        <div className={styles.dropdown}>
                            <div className={styles.dropdownOption} onClick={async() => await logoutMain(dispatch)}>
                                <p className={styles.dropdownOptionText}>Logout</p>
                                <i className={`${styles.dropdownOptionIcon} fa-solid fa-arrow-right-from-bracket`} />
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
            {showAddRevenueModal && <AddRevenueModal close={() => setShowAddRevenueModal(false)}/>}
            {showSettings && <Settings close={() => setShowSettings(false)}/>}
        </div>
    )
}

export default Header;
