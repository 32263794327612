import { useSelector } from 'react-redux';
import styles from './Settings.module.css';
import { RootState } from '../../Redux/store';
import { useEffect, useRef, useState } from 'react';
import { ApiResponse } from '../../interfaces';
import MainSpinner from '../Spinners/MainSpinner';
import ErrorModal from '../Error/ErrorModal';

function Settings(props: {close: () => any;}) {
    const threshold = useSelector((state: RootState) => state.auth.userData?.threshold);
    const startOfWeek = useSelector((state: RootState) => state.auth.userData?.startOfWeek);
    const accessToken = useSelector((state: RootState) => state.auth.accessToken);

    const [originalThreshold, setOriginalThreshold] = useState(threshold);
    const [originalStartOfWeek, setOriginalStartOfWeek] = useState(startOfWeek);
    const [newThreshold, setNewThreshold] = useState(threshold?.toFixed(2));
    const [newStartOfWeek, setNewStartOfWeek] = useState(startOfWeek);
    const [isThresholdValid, setIsThresholdValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [shouldReload, setShouldReload] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [error, setError] = useState<string | null>(null);

    const env = process.env.NODE_ENV || 'development';

    let baseUrl = "";

    if (env != 'development') {
        baseUrl = process.env.REACT_APP_BACKEND_URL!;
    }

    const apiUrl = `${baseUrl}/api/user/updateSettings`;

    function thresholdInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        setNewThreshold(event.target.value);
    };

    function thresholdValidation(value: string | undefined) {
        if (!value) return false;
        const regex = /^(?:[0-9]{1,7}(?:\.[0-9]{1,2})?)$/;
        return regex.test(value);
    };

    async function handleSubmit() {
        setIsThresholdValid(true);

        const isValidThreshold = thresholdValidation(newThreshold);

        if (!isValidThreshold) {
            setIsThresholdValid(false);
            return;
        }

        if (newThreshold == originalThreshold && newStartOfWeek == originalStartOfWeek) {
            setIsSuccess(true);
            window.location.reload();
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                body: JSON.stringify({
                    threshold: parseFloat(newThreshold!),
                    startOfWeek: newStartOfWeek!,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken,
                }
            })

            const contentType = response.headers.get('Content-Type');
            if (!contentType || !contentType.includes('application/json')) throw new Error("Unexpected error occured.");

            const data: ApiResponse<void> = await response.json();

            if (!response.ok) {
                if (response.status == 400 || response.status == 401) setShouldReload(true);
                throw new Error(data.message);
            }

            setIsSuccess(true);
            window.location.reload();
        }
        catch (err: unknown) {
            if (err instanceof Error) setError(err.message);
            else setError("An unexpected error occurred, please try again.");
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setOriginalThreshold(threshold);
    }, [threshold]);

    useEffect(() => {
        setOriginalStartOfWeek(startOfWeek);
    }, [startOfWeek]);

    return (
        <div className={styles.settingsBackdrop} onMouseDown={props.close}>
            <div className={styles.settings} onMouseDown={(e) => e.stopPropagation()}>
                <p className={styles.title}>Settings</p>
                <div className={styles.options}>
                    <div className={styles.option}>
                        <p className={styles.optionTitle}>Start of week</p>
                        <div className={styles.optionChoices}>
                            <p className={newStartOfWeek == 7 ? styles.optionChoice1: styles.optionChoice0} onClick={() => setNewStartOfWeek(7)}>Sunday</p>
                            <p className={newStartOfWeek == 1 ? styles.optionChoice1: styles.optionChoice0} onClick={() => setNewStartOfWeek(1)}>Monday</p>
                        </div>
                    </div>
                    <div className={styles.option}>
                        <p className={styles.optionTitle}>Break-even (monthly)</p>
                        <div className={`${styles.inputContainer} ${styles.noMargin}`}>
                            <div className={styles.inputContainerMain}>
                                <i className={`${styles.inputContainerIcon} fa-solid fa-dollar-sign`}></i>
                                <input
                                    className={`${styles.input} ${styles.inputPadding}`}
                                    placeholder="newThreshold"
                                    value={newThreshold}
                                    onChange={thresholdInputHandler}
                                    required
                                />
                            </div>
                            {!isThresholdValid && <p className={styles.error}>Please enter a valid threshold.</p>}
                        </div>
                    </div>
                </div>
                <button className={styles.button} style={{backgroundColor: isSuccess ? "var(--green)" : isSuccess == false ? "var(--red)" : "var(--blue)"}} onClick={async() => await handleSubmit()}>
                    {isSuccess ? <i className="fa-solid fa-check" style={{color: "white", fontSize: 18}}/> : "Add"}
                </button>
                {isLoading && <MainSpinner />}
                {error && <ErrorModal message={error} action={!shouldReload ? () => null : () => window.location.reload()} closeModal={() => setError(null)} />}
            </div>
        </div>
    )
}

export default Settings;
