import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './RawData.module.css';
import Header from '../Components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { ApiResponse, CustomErrorInfo, RevenueInput } from '../interfaces';
import { format, utcToZonedTime } from 'date-fns-tz';
import { setRawRevenueData } from '../Redux/slices/rawDataSlice';
import MainSpinner from '../Components/Spinners/MainSpinner';
import EditRevenueModal from '../Components/Modals/EditRevenueModal';

function RawData() {
    const accessToken = useSelector((state: RootState) => state.auth.accessToken);
    const rawRevenueData = useSelector((state: RootState) => state.rawData.rawRevenueData);
    const threshold = useSelector((state: RootState) => state.auth.userData?.threshold);
    const startOfWeek = useSelector((state: RootState) => state.auth.userData?.startOfWeek);
    const tsStartOfWeek = startOfWeek != undefined ? (startOfWeek == 7 ? 0 : startOfWeek) : 1;

    const [error, setError] = useState<CustomErrorInfo | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [rawDataAlignBy, setRawDataAlignBy] = useState<0 | 1>(0);
    const [rawDataSortBy, setRawDataSortBy] = useState<0 | 1>(0);
    const [showEditRevenueModal, setShowEditRevenueModal] = useState("");
    const [prevAmount, setPrevAmount] = useState(0);

    const rawDataAlignByKey = 'rawDataAlignBy';
    const rawDataSortByKey = 'rawDataSortBy';

    const handleRawDataAlignByChange = (value: 0 | 1) => {
        setRawDataAlignBy(value);
        localStorage.setItem(rawDataAlignByKey, value.toString());
    };

    const handleRawDataSortByChange = (value: 0 | 1) => {
        setRawDataSortBy(value);
        localStorage.setItem(rawDataSortByKey, value.toString());
    };

    const env = process.env.NODE_ENV || 'development';

    let baseUrl = "";

    if (env != 'development') {
        baseUrl = process.env.REACT_APP_BACKEND_URL!;
    }

    const apiUrl = `${baseUrl}/api/user/fetchRawRevenueData`;

    const yearlyMetrics = new Map<number, { quarters: Map<number, number>, months: Map<number, number> }>();

    const rawDataMetricsRef = useRef<HTMLDivElement>(null);
    const rawDataLowerRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const date = new Date();
    const formattedDate = format(date, 'EEEE, d MMMM yyyy');

    async function fetchRawRevenueData() {
        try {
            setIsLoading(true);

            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + accessToken
                }
            });

            const contentType = response.headers.get('Content-Type');
            if (!contentType || !contentType.includes('application/json')) throw new Error("Unexpected error occured.");

            const data: ApiResponse<RevenueInput[]> = await response.json();

            if (!response.ok) throw new Error("Your session has expired, please log back in to continue.");

            setStartDate(data.data[0].date);
            setEndDate(data.data[data.data.length - 1].date);
            dispatch(setRawRevenueData(data.data));
        }
        catch (err: any) {
            const error: CustomErrorInfo = {
                message: err.message,
                statusCode: 400,
                action: () => null
            }
            setError(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    function renderYearlyMetrics() {
        if (yearlyMetrics.size == 0) return;

        const arr = [];
        const yearlyMetricsArray = Array.from(yearlyMetrics.entries());

        for (let i = 0; i < yearlyMetricsArray.length; i++) {
            const [year, metrics] = yearlyMetricsArray[i];
            const { quarters, months } = metrics;
            const totalRevenue = Array.from(months.values()).reduce((acc, value) => acc + value, 0);
            const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

            const children = [
                <p className={styles.rawDataMetricsBodyOption} key={`year-${year}`}>{year}</p>,
                <p className={styles.rawDataMetricsBodyOption} key={`revenue-${year}`}>{formatter.format(totalRevenue)}</p>
            ];

            for (let quarter = 1; quarter <= 4; quarter++) {
                children.push(
                    <p key={`quarter-${quarter}-${year}`} className={styles.rawDataMetricsBodyOption}>{formatter.format(quarters.get(quarter) || 0)}</p>
                );
            }

            for (let month = 1; month <= 12; month++) {
                children.push(
                    <p key={`month-${month}-${year}`} className={styles.rawDataMetricsBodyOption}>{formatter.format(months.get(month) || 0)}</p>
                );
            }

            arr.push(
                <div className={styles.rawDataMetricsBodyRow} key={year}>
                    {children}
                </div>
            );
        }

        return rawDataSortBy == 0 ? arr : arr.reverse();
    }

    function renderRawRevenueData() {
        if (rawRevenueData.length == 0 || !startDate || !endDate) return;
        setIsLoading(true);
        // const startTime = performance.now();

        const revenueDataByDate = new Map<string, RevenueInput>();

        for (let i = 0; i < rawRevenueData.length; i++) {
            const revenueInput = rawRevenueData[i];
            const formattedDate = revenueInput.date.split('T')[0];
            revenueDataByDate.set(formattedDate, revenueInput);
        }

        const elements: React.ReactNode[] = [];
        const startYear = new Date(startDate).getUTCFullYear();
        const endYear = new Date(endDate).getUTCFullYear();

        const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

        for (let year = startYear; year <= endYear; year++) {
            if (!yearlyMetrics.has(year)) yearlyMetrics.set(year, { quarters: new Map(), months: new Map() });
            const yearMetrics = yearlyMetrics.get(year)!;
            let monthlyRevenue = 0;
            let quarterlyRevenue = 0;
            let currentQuarter = 0;

            const rows = [];
            let weeklyRevenue = 0;
            let dayCount = 0;

            for (let month = 0; month < 12; month++) {
                monthlyRevenue = 0;
                if ((month) % 3 == 0) {
                    quarterlyRevenue = 0;
                    currentQuarter++;
                }

                let firstStartOfWeekDays = 1;

                const daysInMonth = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();

                if (rawDataAlignBy == 1) {
                    while (new Date(Date.UTC(year, month, firstStartOfWeekDays)).getUTCDay() != tsStartOfWeek) {
                        firstStartOfWeekDays++;
                    }
                }

                const startDay = month == 0 && rawDataAlignBy == 1 ? firstStartOfWeekDays : 1;
                for (let day = startDay; day <= daysInMonth; day++) {
                    if (!isValidDate(year, month, day)) continue;

                    const date = new Date(Date.UTC(year, month, day));
                    const zonelessTime = utcToZonedTime(date, 'Etc/UTC');
                    const formattedDate = format(zonelessTime, 'yyyy-MM-dd', { timeZone: 'Etc/UTC' });

                    const revenueInput = revenueDataByDate.get(formattedDate) || { amount: 0 };
                    const dayString = format(zonelessTime, "E");

                    monthlyRevenue += revenueInput.amount;
                    quarterlyRevenue += revenueInput.amount;
                    weeklyRevenue += revenueInput.amount;
                    dayCount++;

                    rows.push(
                        revenueInput.amount == 0 ? (
                            <div key={`${year}-${month}-${day}`} className={styles.rawDataLowerMainColumnRow}>
                                <p className={styles.rawDataLowerMainColumnRowItem}>{dayString}</p>
                                <p className={`${styles.rawDataLowerMainColumnRowItem} ${styles.rawDataLowerMainColumnRowDate}`}>{formattedDate}</p>
                                <p className={`${styles.rawDataLowerMainColumnRowItem} ${styles.hoverPointer}`} onClick={() => {
                                    setShowEditRevenueModal(formattedDate);
                                    setPrevAmount(revenueInput.amount);
                                }}>--</p>
                            </div>
                        ) : (
                            <div key={`${year}-${month}-${day}`} className={styles.rawDataLowerMainColumnRow} style={{backgroundColor: `${revenueInput.amount >= (threshold! > 0 ? threshold! / daysInMonth : 2000000000) ? "var(--transparentGreen)" : "transparent"}`}}>
                                <p className={styles.rawDataLowerMainColumnRowItem}>{dayString}</p>
                                <p className={`${styles.rawDataLowerMainColumnRowItem} ${styles.rawDataLowerMainColumnRowDate}`}>{formattedDate}</p>
                                <p className={`${styles.rawDataLowerMainColumnRowItem} ${styles.hoverPointer}`} onClick={() => {
                                    setShowEditRevenueModal(formattedDate);
                                    setPrevAmount(revenueInput.amount);
                                }}>{formatter.format(revenueInput.amount)}</p>
                            </div>
                        )
                    );

                    if (dayCount == 7) {
                        rows.push(
                            <div key={`weekly-${year}-${month}-${day}`} className={styles.rawDataLowerMainColumnRow} style={{backgroundColor: "var(--offWhite)"}}>
                                <p className={styles.rawDataLowerMainColumnRowItem} style={{width: "66%"}}>{"Weekly Revenue: "}</p>
                                <p className={`${styles.rawDataLowerMainColumnRowItem}`}>{formatter.format(weeklyRevenue)}</p>
                            </div>
                        );

                        weeklyRevenue = 0;
                        dayCount = 0;
                    }
                }

                if ((month + 1) % 3 == 0) yearMetrics.quarters.set(currentQuarter, quarterlyRevenue);
                yearMetrics.months.set(month + 1, monthlyRevenue);
            }

            elements.push(
                <div key={year} className={styles.rawDataLowerMainColumn}>
                    <p className={styles.rawDataLowerMainColumnHeader}>{year}</p>
                    {rows}
                </div>
            );
        }

        function isValidDate(year: number, month: number, day: number) {
            const tempDate = new Date(Date.UTC(year, month, day));
            return tempDate.getUTCFullYear() == year &&
                   tempDate.getUTCMonth() == month &&
                   tempDate.getUTCDate() == day;
        }

        // const endTime = performance.now();
        // const timeTaken = endTime - startTime;
        // console.log(`Time taken to execute renderRawRevenueData: ${timeTaken} milliseconds`);

        setIsLoading(false);
        return rawDataSortBy == 0 ? elements : elements.reverse();
    }

    const rawRevenueElements = useMemo(() => renderRawRevenueData(), [rawRevenueData, startDate, endDate, rawDataAlignBy, rawDataSortBy]);
    const yearlyMetricElements = useMemo(() => renderYearlyMetrics(), [rawRevenueElements, rawDataAlignBy, rawDataSortBy]);

    const handleMouseDown = (ref: React.RefObject<HTMLDivElement>) => (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDown(true);
        setStartX(e.pageX - (ref.current?.offsetLeft || 0));
        setScrollLeft(ref.current?.scrollLeft || 0);
    };

    const handleMouseLeave = () => setIsDown(false);

    const handleMouseUp = () => setIsDown(false);

    const handleMouseMove = (ref: React.RefObject<HTMLDivElement>) => (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - (ref.current?.offsetLeft || 0);
        const walk = (x - startX) * 1.2;
        ref.current!.scrollLeft = scrollLeft - walk;
    };

    useEffect(() => {
        if (rawRevenueData.length == 0) fetchRawRevenueData();
        else {
            setStartDate(rawRevenueData[0].date);
            setEndDate(rawRevenueData[rawRevenueData.length - 1].date);
        }
    }, [rawRevenueData]);

    useEffect(() => {
        const alignBy = localStorage.getItem(rawDataAlignByKey);
        const sortBy = localStorage.getItem(rawDataSortByKey);

        if (alignBy) setRawDataAlignBy(Number(alignBy) as 0 | 1);
        if (sortBy) setRawDataSortBy(Number(sortBy) as 0 | 1);
    }, []);

    return (
        <div className={styles.rawData}>
            <Header />
            <div className={styles.rawDataUpper}>
                <div className={styles.rawDataUpperLeft}>
                    <p className={styles.rawDataSubText}>{`Raw Revenue Data`}</p>
                    <p className={styles.rawDataWelcomeText}>{`All Time`}</p>
                </div>
                <div className={styles.rawDataUpperRight}>
                    <p className={styles.rawDataSubText}>{formattedDate}</p>
                    <div className={styles.rawDataNav}>
                        {/* <div className={styles.rawDataNavToggle} onClick={() => setShowDropdown(!showDropdown)}>
                            <p className={styles.rawDataNavToggleText}>All Time</p>
                            <i className={`${styles.rawDataNavToggleIcon} fa-solid ${!showDropdown ? `fa-angle-down` : `fa-angle-up`}`}></i>
                        </div>
                        {showDropdown &&
                            <Fragment>
                                <div className={styles.rawDataNavDropdownBackdrop} onClick={() => setShowDropdown(false)} />
                                <div className={styles.rawDataNavDropdown}>
                                    <div className={styles.rawDataNavOption}>
                                        <p className={styles.rawDataNavOptionText}>All time</p>
                                    </div>
                                </div>
                            </Fragment>
                        } */}
                    </div>
                </div>
            </div>
            <div className={styles.rawDataLower}>
            <div className={styles.rawDataHeaderContainer}>
                <p className={styles.rawDataLowerHeader}>Total Revenue</p>
            </div>
                <div
                    className={`${styles.rawDataMetrics} ${isDown ? styles.grabbingCursor : ''}`}
                    ref={rawDataMetricsRef}
                    onMouseDown={handleMouseDown(rawDataMetricsRef)}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove(rawDataMetricsRef)}
                >
                    <div className={styles.rawDataMetricsHeader}>
                        <p className={styles.rawDataMetricsHeaderOption}>Year</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Total</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Q1</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Q2</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Q3</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Q4</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Jan</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Feb</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Mar</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Apr</p>
                        <p className={styles.rawDataMetricsHeaderOption}>May</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Jun</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Jul</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Aug</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Sep</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Oct</p>
                        <p className={styles.rawDataMetricsHeaderOption}>Nov</p>
                        <p className={styles.rawDataMetricsHeaderOption} style={{borderRight: "none"}}>Dec</p>
                    </div>
                    <div className={styles.rawDataMetricsBody}>
                        {yearlyMetricElements}
                        {rawRevenueData.length == 0 && <p style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: 16, fontWeight: 500}}>No revenue data available.</p>}
                    </div>
                </div>
                <div className={styles.rawDataHeaderContainer}>
                    <p className={styles.rawDataLowerHeader}>Raw Data</p>
                    <div className={styles.alignOptions}>
                        <p className={styles.alignByText}>Sort Years By: </p>
                        <div className={styles.optionChoices}>
                            <p className={rawDataSortBy == 0 ? styles.optionChoice1 : styles.optionChoice0} onClick={() => handleRawDataSortByChange(0)}>Ascending</p>
                            <p className={rawDataSortBy == 1 ? styles.optionChoice1 : styles.optionChoice0} onClick={() => handleRawDataSortByChange(1)}>Descending</p>
                        </div>
                    </div>
                    <div className={styles.alignOptions}>
                        <p className={styles.alignByText}>Align Data By: </p>
                        <div className={styles.optionChoices}>
                            <p className={rawDataAlignBy == 0 ? styles.optionChoice1 : styles.optionChoice0} onClick={() => handleRawDataAlignByChange(0)}>Date</p>
                            <p className={rawDataAlignBy == 1 ? styles.optionChoice1 : styles.optionChoice0} onClick={() => handleRawDataAlignByChange(1)}>Day</p>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.rawDataLowerMain}
                    ref={rawDataLowerRef}
                    onMouseDown={handleMouseDown(rawDataLowerRef)}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove(rawDataLowerRef)}
                >
                    {rawRevenueElements}
                    {rawRevenueData.length == 0 && <p style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: 16, fontWeight: 500}}>No revenue data available.</p>}
                </div>
            </div>
            {isLoading && <MainSpinner />}
            {showEditRevenueModal && <EditRevenueModal close={() => setShowEditRevenueModal("")} dateISOString={showEditRevenueModal} prevAmount={prevAmount}/>}
        </div>
    )
}

export default RawData;
