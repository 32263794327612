import { Dispatch } from "@reduxjs/toolkit";
import { logout } from "../../Redux/slices/authSlice";

export async function logoutMain(dispatch: Dispatch): Promise<void> {
    const env = process.env.NODE_ENV || 'development';

    let baseUrl = "";

    if (env != 'development') {
        baseUrl = process.env.REACT_APP_BACKEND_URL!;
    }

    const apiUrl = `${baseUrl}/api/auth/logout`;

    try {
        const response = await fetch(apiUrl, {
            method: "GET",
            credentials: "include",
        });

        if (!response.ok) throw new Error("Logout failed.");
    }
    catch (err: any) {
        console.log(err.message);
    }
    finally {
        dispatch(logout());
    }
}
