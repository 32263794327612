import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RawData, RevenueInput } from '../../interfaces';

const initialState: RawData = {
    rawRevenueData: [],
}

export const rawDataSlice = createSlice({
    name: 'rawData',
    initialState,
    reducers: {
        setRawRevenueData: (state, action: PayloadAction<RevenueInput[]>) => {
            state.rawRevenueData = action.payload;
        },
        clearRawRevenueData: (state) => {
            state.rawRevenueData = [];
        },
    }
});

export const { setRawRevenueData, clearRawRevenueData } = rawDataSlice.actions;
export default rawDataSlice.reducer;
