import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserAuth, MainUserData, MainUserDataResponse } from '../../interfaces';

const initialState: UserAuth = {
    accessToken: null,
    isLoggedIn: false,
    needsAuth: false,
    userData: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<MainUserDataResponse>) => {
            state.accessToken = action.payload.accessToken;
            state.userData = action.payload.userData;
            state.isLoggedIn = true;
            state.needsAuth = false;
            localStorage.setItem("na-x", '0');
        },
        logout: (state) => {
            state.accessToken = null;
            state.userData = null;
            state.isLoggedIn = false;
            state.needsAuth = true;
            localStorage.setItem("na-x", '1');
        },
        setNeedsAuth: (state, action: PayloadAction<boolean>) => {
            state.needsAuth = action.payload;
        },
        setUserData: (state, action: PayloadAction<MainUserData>) => {
            state.userData = action.payload;
        }
    }
});

export const { login, logout, setNeedsAuth } = authSlice.actions;
export default authSlice.reducer;
