import { RingLoader } from 'react-spinners';
import styles from './MainSpinner.module.css';

function MainSpinner() {
    return (
        <div className={styles.mainSpinnerBackdrop} onClick={(e) => e.stopPropagation()}>
            <div className={styles.mainSpinner} onClick={(e) => e.stopPropagation()}>
                <RingLoader color="#267dda" speedMultiplier={1.2}/>
            </div>
        </div>
    )
}

export default MainSpinner;
