import { useRef, useState } from 'react';
import styles from './Login.module.css';
import { ApiResponse, MainUserDataResponse } from '../../interfaces';
import { useDispatch } from 'react-redux';
import { login } from '../../Redux/slices/authSlice';
import MainSpinner from '../Spinners/MainSpinner';
import ErrorModal from '../Error/ErrorModal';

function Login(props: any) {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const env = process.env.NODE_ENV || 'development';

    let baseUrl = "";

    if (env != 'development') {
        baseUrl = process.env.REACT_APP_BACKEND_URL!;
    }

    const apiUrl = `${baseUrl}/api/auth/login`;

    const dispatch = useDispatch();

    function isEmailValid(email: string) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    async function handleSubmit() {
        let errorSet = false;
        setError(null);

        if (!emailRef.current || !passwordRef.current || passwordRef.current.value.trim().length == 0) return;
        if (!isEmailValid(emailRef.current.value)) {
            setError("Invalid email.");
            errorSet = true;
        }

        if (errorSet) return;

        setIsLoading(true);

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                body: JSON.stringify({
                    email: emailRef.current!.value.toLowerCase(),
                    password: passwordRef.current!.value,
                }),
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            const contentType = response.headers.get('Content-Type');
            if (!contentType || !contentType.includes('application/json')) {
                throw new Error("Unexpected error occured.");
            }

            const data: ApiResponse<MainUserDataResponse> = await response.json();

            if (!response.ok) throw new Error(data.message);

            dispatch(login(data.data));
        }
        catch (err: unknown) {
            if (err instanceof Error) setError(err.message);
            else setError("An unexpected error occurred, please try again.");
        }
        finally {
            setIsLoading(false);
        }
    }
    return (
        <div className={styles.loginBackdrop} onMouseDown={() => {if (!isLoading) props.close()}}>
            <div className={styles.login} onMouseDown={(e) => e.stopPropagation()}>
                <p className={styles.loginTitle}>Log into your Growth account.</p>
                <div className={styles.inputContainer}>
                    <p className={styles.inputLabel}>Email</p>
                    <input ref={emailRef} type="email" className={styles.input} maxLength={40} />
                </div>
                <div className={styles.inputContainer}>
                    <p className={styles.inputLabel}>Password</p>
                    <input ref={passwordRef} type="password" className={styles.input} maxLength={30}/>
                </div>
                <button className={styles.button} onClick={handleSubmit}>Log In</button>
                <p className={styles.switch} onClick={props.switch}>Don't have an account? Sign up.</p>
                {isLoading && <MainSpinner />}
                {error && <ErrorModal message={error} action={() => null} closeModal={() => setError(null)} />}
            </div>
        </div>
    )
}

export default Login;
