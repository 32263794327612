import { useSelector } from 'react-redux';
import { ChartDataArray } from '../../interfaces';
import styles from './RevenueChart.module.css';
import { CartesianGrid, TooltipProps, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from "recharts";
import { RootState } from '../../Redux/store';

function RevenueChart(props: { data?: ChartDataArray; lastData?: ChartDataArray; timeframe?: number; }) {
    const threshold = useSelector((state: RootState) => state.auth.userData?.threshold);

    const placeholderData = [
        { name: 'Jan', amount: 22000 },
        { name: 'Feb', amount: 24000 },
        { name: 'Mar', amount: 27000 },
        { name: 'Apr', amount: 30000 },
        { name: 'May', amount: 29000 },
        { name: 'Jun', amount: 32000 },
        { name: 'Jul', amount: 31000 },
        { name: 'Aug', amount: 36000 },
        { name: 'Sep', amount: null },
        { name: 'Oct', amount: null },
        { name: 'Nov', amount: null },
        { name: 'Dec', amount: null }
    ];

    const placeholderLastData = [
        { name: 'Jan', amount: 22000 },
        { name: 'Feb', amount: 25000 },
        { name: 'Mar', amount: 26000 },
        { name: 'Apr', amount: 28000 },
        { name: 'May', amount: 27000 },
        { name: 'Jun', amount: 31000 },
        { name: 'Jul', amount: 30000 },
        { name: 'Aug', amount: 34000 },
        { name: 'Sep', amount: null },
        { name: 'Oct', amount: null },
        { name: 'Nov', amount: null },
        { name: 'Dec', amount: null }
    ];

    function transformData(dataArray: ChartDataArray, timeframe?: number) {
        const filteredData = dataArray.filter(item => {
            const date = Object.keys(item)[0];
            if (timeframe == 2) {
                const [year, month] = date.split("-").map(Number);
                const localDate = new Date(year, month - 1);
                const monthIndex = localDate.getMonth();
                const currentMonth = new Date().getMonth();
                const currentQuarter = Math.floor(currentMonth / 3);
                const startQuarterMonth = currentQuarter * 3;
                return monthIndex <= currentMonth && monthIndex >= startQuarterMonth;
            }
            return true;
        })

        const finalData = (timeframe == 0) ? filteredData : filteredData.slice(0, -1);

        return finalData.map((item, idx) => {
            const date = Object.keys(item)[0];
            let amount = item[date];
            let name = date;
            amount = parseFloat(amount.toFixed(2));

            if (timeframe == 0) {
                const [year, month, day] = date.split("-").map(Number);
                const localDate = new Date(year, month - 1, day);
                const dayOfWeek = localDate.getDay();
                const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                name = dayNames[dayOfWeek];
            }
            else if (timeframe == 1) {
                const [, month, day] = date.split("-");
                name = `Week ${idx + 1}`;
            }
            else if (timeframe == 2 || timeframe == 3) {
                const [year, month, day] = date.split("-").map(Number);
                const localDate = new Date(year, month - 1, day);
                const monthIndex = localDate.getMonth();
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                if (timeframe == 2) {
                    const currentMonth = new Date().getMonth();
                    const currentQuarter = Math.floor(currentMonth / 3);
                    const startQuarterMonth = currentQuarter * 3;

                    if (monthIndex <= currentMonth && monthIndex >= startQuarterMonth) name = monthNames[monthIndex];
                }
                else if (timeframe == 3) name = monthNames[monthIndex];
            }
            else if (timeframe == 4) {
                const [year] = date.split("-");
                name = year;
            }

            return { name: name, amount: amount };
        });
    }

    const transformedData = (props.data) ? transformData(props.data, props.timeframe) : (props.timeframe == undefined ? placeholderData : []);
    const transformedLastData = props.lastData ? transformData(props.lastData, props.timeframe) : (props.timeframe == undefined ? placeholderLastData : []);

    const combinedData = transformedData.map((data, index) => ({
        ...data,
        previousYearAmount: transformedLastData[index] ? transformedLastData[index].amount : null,
    }));
    
    function CustomTooltip({ active, payload }: TooltipProps<any, any>) {
        if (active && payload) {
            return (
                <div className={styles.customTooltip}>
                    {payload.map((item) => (
                        <p key={item.dataKey} className={styles.intro}>
                            {item.dataKey == "amount" ? "Revenue" : "Previous Revenue"} : ${item.value}
                        </p>
                    ))}
                </div>
            );
        }
    
        return null;
    }    

    return (
        <ResponsiveContainer width={"100%"} height={340}>
            <LineChart data={combinedData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Line type="monotone" dataKey="amount" stroke="#007AFF" strokeWidth={2} dot={true}/>
            <Line type="monotone" dataKey="previousYearAmount" stroke="#22222270" strokeWidth={2} dot={true} />
                <CartesianGrid stroke="#e8e8e8" strokeDasharray="10 10" vertical={false}/>
                <XAxis
                    scale="auto"
                    tickLine={false}
                    dataKey="name"
                    tick={{ fill: '#a1a1a1', fontSize: 12, dy: 10 }}
                    axisLine={{stroke: '#e0e0e0'}}
                    height={40}
                />
                <YAxis
                    scale="auto"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={value => `$${value}`}
                    tick={{ fill: '#a1a1a1', fontSize: 12, dx: -10 }}
                    domain={['auto', 'auto']}
                    width={80}
                />
                <ReferenceLine y={threshold} stroke="#267dda" strokeDasharray="10 10" strokeWidth={1}/>
                <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '10 10', stroke: '#e8e8e8' }} />
                <Legend verticalAlign='top' wrapperStyle={{ textAlign: 'center', width: 'calc(100% - 30px)', paddingBottom: 28, fontSize: 14, marginLeft: 30, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}} iconType='plainline' formatter={(value) => {
                const formattedValue = value.replace(/([A-Z])/g, ' $1').trim();
                return formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
                }} iconSize={16}/>
            </LineChart>
        </ResponsiveContainer>
    )
}

export default RevenueChart;
