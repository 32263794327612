import { Fragment, useState } from 'react';
import styles from './Home.module.css';
import RevenueChart from '../Components/Charts/RevenueChart';
import Header from '../Components/Header';
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { Metrics } from '../interfaces';
import { utcToZonedTime, format } from 'date-fns-tz';

function Home() {
    const [timeframe, setTimeframe] = useState(0);

    const overallMetrics = useSelector((state: RootState) => state.auth.userData?.overallMetrics);

    console.log(overallMetrics?.chartData);

    const timeframes: Record<number, string> = {
        0: 'Week',
        1: 'Month',
        2: 'Quarter',
        3: 'Year',
        4: 'All time'
    };

    const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const metrics = overallMetrics ? timeframe < 4 ? overallMetrics[timeframes[timeframe].toLowerCase()] as Metrics : overallMetrics.allTime : null;
    const date = new Date();
    const formattedDate = format(date, 'EEEE, d MMMM yyyy');
    const revenueChartData = () => {
        switch (timeframe) {
            case 0:
                return overallMetrics?.chartData.weekDatapoints;
            case 1:
                return overallMetrics?.chartData.monthDatapoints;
            case 2:
                return overallMetrics?.chartData.yearDatapoints;
            case 3:
                return overallMetrics?.chartData.yearDatapoints;
            case 4:
                return overallMetrics?.chartData.allTimeDatapoints;
            default:
                return undefined;
        }
    }

    const revenueLastChartData = () => {
        switch (timeframe) {
            case 0:
                return overallMetrics?.chartData.lastWeekDatapoints;
            case 1:
                return overallMetrics?.chartData.lastMonthDatapoints;
            case 2:
                return overallMetrics?.chartData.lastYearDatapoints;
            case 3:
                return overallMetrics?.chartData.lastYearDatapoints;
            default:
                return undefined;
        }
    }

    const dailyAveragePercentDifference = metrics ? parseFloat(metrics.dailyAverageDifferencePercent.toFixed(2)) : 0.00;
    const totalRevenuePercentDifference = metrics ? parseFloat(metrics.totalRevenueDifferencePercent.toFixed(2)) : 0.00;

    return (
        <div className={styles.home}>
            <Header />
            <div className={styles.homeUpper}>
                <div className={styles.homeUpperInner}>
                <div className={styles.homeUpperLeft}>
                    <p className={styles.homeSubText}>{timeframe != 4 ? `This ${timeframes[timeframe]}'s Performance` : 'All Time Performance'}</p>
                    <p className={styles.homeWelcomeText}>
                        {metrics ? (
                                (timeframe == 0
                                    ? format(utcToZonedTime(metrics.startDate, 'UTC'), 'EEE, MMM d', { timeZone: 'UTC' })
                                    : format(utcToZonedTime(metrics.startDate, 'UTC'), 'MMM d yyyy', { timeZone: 'UTC' }))
                                + " - "
                                + (timeframe == 0
                                    ? format(utcToZonedTime(metrics.endDate, 'UTC'), 'EEE, MMM d', { timeZone: 'UTC' })
                                    : format(utcToZonedTime(metrics.endDate, 'UTC'), 'MMM d yyyy', { timeZone: 'UTC' }))
                            )
                                :
                            'No revenue data available.'}
                    </p>
                </div>
                <div className={styles.homeUpperRight}>
                    <p className={styles.homeSubText}>{formattedDate}</p>
                    <div className={styles.homeNav}>
                        <div className={timeframe == 0 ? styles.selectedNavOption : styles.homeNavOption} onClick={() => setTimeframe(0)}>
                            <p className={styles.homeNavOptionText}>Week</p>
                        </div>
                        <div className={timeframe == 1 ? styles.selectedNavOption : styles.homeNavOption} onClick={() => setTimeframe(1)}>
                            <p className={styles.homeNavOptionText}>Month</p>
                        </div>
                        <div className={timeframe == 2 ? styles.selectedNavOption : styles.homeNavOption} onClick={() => setTimeframe(2)}>
                            <p className={styles.homeNavOptionText}>Quarter</p>
                        </div>
                        <div className={timeframe == 3 ? styles.selectedNavOption : styles.homeNavOption} onClick={() => setTimeframe(3)}>
                            <p className={styles.homeNavOptionText}>Year</p>
                        </div>
                        <div className={timeframe == 4 ? styles.selectedNavOption : styles.homeNavOption} onClick={() => setTimeframe(4)}>
                            <p className={styles.homeNavOptionText}>All time</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className={styles.homeMain}>
                <div className={styles.homeMainInner}>
                <div className={styles.kpisContainer}>
                    <div className={styles.kpi}>
                        <div className={styles.kpiUpper}>
                            <p className={styles.kpiUpperTitle}>Total Revenue</p>
                            {timeframe != 4 && <div className={styles.kpiCompare} style={totalRevenuePercentDifference < 0 ? {backgroundColor: "var(--black)"} : {backgroundColor: "var(--transparentBlue)"}}>
                                <i className={`${totalRevenuePercentDifference < 0 ? styles.kpiCompareIcon1 : styles.kpiCompareIcon0} fa-solid ${totalRevenuePercentDifference < 0 ? "fa-arrow-trend-down" : "fa-arrow-trend-up"}`}></i>
                                <p className={totalRevenuePercentDifference < 0 ? styles.kpiComparePercent1 : styles.kpiComparePercent0}>{metrics ? totalRevenuePercentDifference + "%" : "0.00%"}</p>
                            </div>}
                        </div>
                        <div className={styles.kpiMiddle}>
                            <p className={styles.kpiValue}>{metrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(metrics.totalRevenue) : 'N/A'}</p>
                        </div>
                        <div className={styles.kpiLower}>
                            {timeframe != 4 &&
                                <Fragment>
                                    <p className={styles.kpiLowerText}>{`vs. last ${timeframes[timeframe].toLowerCase()}: `}</p>
                                    <p className={styles.kpiLowerValue}>{metrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(metrics.lastTotalRevenue) : 'N/A'}</p>
                                </Fragment>
                            }
                        </div>
                    </div>
                    <div className={styles.kpi}>
                        <div className={styles.kpiUpper}>
                            <p className={styles.kpiUpperTitle}>{`Daily Average`}</p>
                            {timeframe != 4 && <div className={styles.kpiCompare} style={dailyAveragePercentDifference < 0 ? {backgroundColor: "var(--black)"} : {backgroundColor: "var(--transparentBlue)"}}>
                                <i className={`${dailyAveragePercentDifference < 0 ? styles.kpiCompareIcon1 : styles.kpiCompareIcon0} fa-solid ${dailyAveragePercentDifference < 0 ? "fa-arrow-trend-down" : "fa-arrow-trend-up"}`}></i>
                                <p className={dailyAveragePercentDifference < 0 ? styles.kpiComparePercent1 : styles.kpiComparePercent0}>{metrics ? dailyAveragePercentDifference + "%" : "0.00%"}</p>
                            </div>}
                        </div>
                        <div className={styles.kpiMiddle}>
                            <p className={styles.kpiValue}>{metrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(metrics.dailyAverage) : 'N/A'}</p>
                        </div>
                        <div className={styles.kpiLower}>
                            {timeframe != 4 &&
                                <Fragment>
                                <p className={styles.kpiLowerText}>{`all time: `}</p>
                                <p className={styles.kpiLowerValue}>{overallMetrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(overallMetrics.allTimeDailyAverage) : "N/A"}</p>
                                </Fragment>
                            }
                        </div>
                    </div>
                    <div className={styles.kpi}>
                        <div className={styles.kpiUpper}>
                            <p className={styles.kpiUpperTitle}>{timeframe != 4 ? `Highest Day This ${timeframes[timeframe]}` : 'All Time Highest Day'}</p>
                        </div>
                        <div className={styles.kpiMiddle}>
                            <p className={styles.kpiValue}>{metrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(metrics.highestDay) : "N/A"}</p>
                        </div>
                        <div className={styles.kpiLower}>
                            <Fragment>
                                <p className={styles.kpiLowerText}>{`all time: `}</p>
                                <p className={styles.kpiLowerValue}>{overallMetrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(overallMetrics.allTimeHighestDay) : "N/A"}</p>
                            </Fragment>
                        </div>
                    </div>
                    <div className={styles.kpi}>
                        <div className={styles.kpiUpper}>
                            <p className={styles.kpiUpperTitle}>{timeframe != 4 ? `Lowest Day This ${timeframes[timeframe]}` : 'All Time Lowest Day'}</p>
                        </div>
                        <div className={styles.kpiMiddle}>
                            <p className={styles.kpiValue}>{metrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(metrics.lowestDay) : "N/A"}</p>
                        </div>
                        <div className={styles.kpiLower}>
                            <Fragment>
                                <p className={styles.kpiLowerText}>{`all time: `}</p>
                                <p className={styles.kpiLowerValue}>{overallMetrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(overallMetrics.allTimeLowestDay) : "N/A"}</p>
                            </Fragment>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className={styles.homeLower}>
                <div className={styles.homeLowerInner}>
                <div className={styles.homeMainMain}>
                    <div className={styles.chartsContainer0}>
                        <div className={styles.revenueChartContainer}>
                            <div className={styles.revenueChartHeader}>
                                <div className={styles.revenueChartTitleContainer}>
                                    <pre className={styles.revenueChartTitle}>
                                        {metrics ? "Revenue Data: " : "No revenue data available."}
                                    </pre>
                                </div>
                            </div>
                            <div className={styles.revenueChartBody}>
                                <RevenueChart data={revenueChartData()} lastData={revenueLastChartData()} timeframe={timeframe}/>
                            </div>
                        </div>
                        <div className={styles.rightChartContainer}>
                            <div className={styles.rightChartHeader}>
                            <p className={styles.rightChartTitle}>All Time Performance:</p>
                            </div>
                            <div className={styles.rightChartBody}>
                                <div className={styles.rightChartBodyOption1}>
                                    <div className={styles.rightChartBodyOptionUpper}>
                                        <pre className={styles.rightChartBodyOptionTitle}>Busiest Day: </pre>
                                        <p className={styles.rightChartBodyOptionText}>{overallMetrics ? dayNames[overallMetrics.busiestDay - 1] : "N/A"}</p>
                                    </div>
                                    <div className={styles.rightChartBodyOptionLower}>
                                        <pre className={styles.rightChartBodyOptionTitle1}>{overallMetrics ? `Average Revenue: ` : "N/A"}</pre>
                                        <p className={styles.rightChartBodyOptionText1}>{overallMetrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(overallMetrics.busiestDayAverage) : ""}</p>
                                    </div>
                                </div>
                                <div className={styles.rightChartBodyOption1}>
                                    <div className={styles.rightChartBodyOptionUpper}>
                                        <pre className={styles.rightChartBodyOptionTitle}>Busiest Month: </pre>
                                        <p className={styles.rightChartBodyOptionText}>{overallMetrics ? monthNames[overallMetrics.busiestMonth - 1] : "N/A"}</p>
                                    </div>
                                    <div className={styles.rightChartBodyOptionLower}>
                                        <pre className={styles.rightChartBodyOptionTitle1}>{overallMetrics ? `Average Revenue: ` : "N/A"}</pre>
                                        <p className={styles.rightChartBodyOptionText1}>{overallMetrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(overallMetrics.busiestMonthAverage) : ""}</p>
                                    </div>
                                </div>
                                <div className={styles.rightChartBodyOption1}>
                                    <div className={styles.rightChartBodyOptionUpper}>
                                        <pre className={styles.rightChartBodyOptionTitle}>Slowest Day: </pre>
                                        <p className={styles.rightChartBodyOptionText}>{overallMetrics ? dayNames[overallMetrics.slowestDay - 1] : "N/A"}</p>
                                    </div>
                                    <div className={styles.rightChartBodyOptionLower}>
                                        <pre className={styles.rightChartBodyOptionTitle1}>{overallMetrics ? `Average Revenue: ` : "N/A"}</pre>
                                        <p className={styles.rightChartBodyOptionText1}>{overallMetrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(overallMetrics.slowestDayAverage) : ""}</p>
                                    </div>
                                </div>
                                <div className={styles.rightChartBodyOption1}>
                                    <div className={styles.rightChartBodyOptionUpper}>
                                        <pre className={styles.rightChartBodyOptionTitle}>Slowest Month: </pre>
                                        <p className={styles.rightChartBodyOptionText}>{overallMetrics ? monthNames[overallMetrics.slowestMonth - 1] : "N/A"}</p>
                                    </div>
                                    <div className={styles.rightChartBodyOptionLower}>
                                        <pre className={styles.rightChartBodyOptionTitle1}>{overallMetrics ? `Average Revenue: ` : "N/A"}</pre>
                                        <p className={styles.rightChartBodyOptionText1}>{overallMetrics ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(overallMetrics.slowestMonthAverage) : ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className={styles.footer}>
                    <a href="https://archetypeavl.com/" className={styles.footerText}>{`© ${new Date().getUTCFullYear()} Archetype`}</a>
                </div>
            </div>
        </div>
    )
}

export default Home;
