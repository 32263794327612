import styles from './Landing.module.css';
import processor from '../Assets/processor.json';
import Lottie from 'react-lottie-player';
import RevenueChart from '../Components/Charts/RevenueChart';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Signup from '../Components/Auth/Signup';
import Login from '../Components/Auth/Login';
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/store';

function Landing() {
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);

    const navigate = useNavigate();

    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            setShowLogin(false);
            setShowSignup(false);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (showLogin || showSignup) document.body.classList.add('preventBgScroll');
        else document.body.classList.remove('preventBgScroll');
    }, [showLogin, showSignup]);

    return (
        <div className={styles.landing}>
            <div className={styles.header}>
                <div className={styles.headerLeft}>
                    <p className={styles.logoText}>Ascend X</p>
                    <a href="#intro" className={`${styles.navOption}`}>Intro</a>
                    <a href="#x12engine" className={`${styles.navOption}`}>X-12 Engine</a>
                    <a href="#keymetrics" className={`${styles.navOption}`}>Key Metrics</a>
                    <a href="#visualize" className={`${styles.navOption} ${styles.noMarginRight}`}>Visualize</a>
                </div>
                <div className={styles.headerRight}>
                    <button className={styles.signup} onClick={() => {if (!isLoggedIn) setShowSignup(true)}}>Join Now</button>
                </div>
            </div>
            <div className={styles.body}>
                <a id="intro"></a>
                <section className={styles.hero}>
                    <p className={styles.heroTitle}>The simple way to track your business's growth.</p>
                    <p className={styles.heroDesc}>Supercharged X-12 Engine, End-to-End Encryption, Beautiful Visualizations.</p>
                    <button className={styles.heroButton} onClick={() => {if (!isLoggedIn) setShowSignup(true)}}>Join Now</button>
                </section>
                <a id="x12engine"></a>
                <section className={styles.engine}>
                    <div className={styles.engineLeft}>
                        <p className={styles.engineLeftTitle}>X-12 Engine.</p>
                        <p className={styles.engineLeftText}>The supercharged X-12 engine achieves extraordinary speed and efficiency through optimization and intelligent algorithms.<br/><br/>By meticulously analyzing complex patterns in historical data, it transforms vast datasets into accurate, actionable metrics
                        <br/><br/>
                        It leverages concurrency, and tailored data structures to deliver blazing fast throughput. Even under heavy loads, the X-12 engine decrypts, processes, and analyzes up to millions of revenue datapoints in just a few milliseconds.</p>
                    </div>
                    <div className={styles.engineRight}>
                        <Lottie animationData={processor} play loop style={{ height: 400, width: 400 }} />
                    </div>
                </section>
                <a id="keymetrics"></a>
                <section className={styles.visual}>
                    <p className={styles.visualTitle}>Key metrics at a glance.</p>
                    <p className={styles.visualDesc}>Unlock unparalleled business insights with key metrics tracking your current success.</p>
                    <div className={styles.kpisContainer}>
                        <div className={styles.kpi}>
                            <div className={styles.kpiUpper}>
                                <p className={styles.kpiUpperTitle}>Total Revenue</p>
                                <div className={styles.kpiCompare}>
                                    <i className={`${styles.kpiCompareIcon} fa-solid fa-arrow-trend-up`}></i>
                                    <p className={styles.kpiComparePercent}>46.8%</p>
                                </div>
                            </div>
                            <div className={styles.kpiMiddle}>
                                <p className={styles.kpiValue}>$524,563.77</p>
                            </div>
                            <div className={styles.kpiLower}>
                                <p className={styles.kpiLowerText}>{`vs. last month: `}</p>
                                <p className={styles.kpiLowerValue}>{`$357,345.84`}</p>
                            </div>
                        </div>
                        <div className={styles.kpi}>
                            <div className={styles.kpiUpper}>
                                <p className={styles.kpiUpperTitle}>{`Daily Average`}</p>
                                <div className={styles.kpiCompare}>
                                    <i className={`${styles.kpiCompareIcon} fa-solid fa-arrow-trend-up`}></i>
                                    <p className={styles.kpiComparePercent}>15.7%</p>
                                </div>
                            </div>
                            <div className={styles.kpiMiddle}>
                                <p className={styles.kpiValue}>$32,876.14</p>
                            </div>
                            <div className={styles.kpiLower}>
                                <p className={styles.kpiLowerText}>{`all time avg: `}</p>
                                <p className={styles.kpiLowerValue}>{`$28,421.58`}</p>
                            </div>
                        </div>
                        <div className={styles.kpi}>
                            <div className={styles.kpiUpper}>
                                <p className={styles.kpiUpperTitle}>{`Highest Day This Month`}</p>
                            </div>
                            <div className={styles.kpiMiddle}>
                                <p className={styles.kpiValue}>$48,986.08</p>
                            </div>
                            <div className={styles.kpiLower}>
                                <p className={styles.kpiLowerText}>{`all time: `}</p>
                                <p className={styles.kpiLowerValue}>{`$62,345.32`}</p>
                            </div>
                        </div>
                        <div className={styles.kpi}>
                            <div className={styles.kpiUpper}>
                                <p className={styles.kpiUpperTitle}>Lowest Day This Month</p>
                            </div>
                            <div className={styles.kpiMiddle}>
                                <p className={styles.kpiValue}>$24,654.34</p>
                            </div>
                            <div className={styles.kpiLower}>
                                <p className={styles.kpiLowerText}>{`all time: `}</p>
                                <p className={styles.kpiLowerValue}>{`$18,435.89`}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <a id="visualize"></a>
                <section className={styles.chart}>
                    <p className={styles.chartTitle}>Visualize your growth.</p>
                    <p className={styles.chartDesc}>Unveil a profound perspective into your business's present achievements.<br/><br/>Stunning visualizations infuse X-12 Engine's triple exponential smoothing algorithms for projected business growth.</p>
                    <div className={styles.chartChart}>
                        <RevenueChart />
                    </div>
                </section>
            </div>
            <div className={styles.footer}>
                <p className={styles.footerText0}>&copy; 2023 Archetype</p>
            </div>

            {!isLoggedIn && showLogin &&
                <Login
                    switch={() => {setShowLogin(false); setShowSignup(true);}}
                    close={() => {setShowLogin(false); setShowSignup(false);}}
                />}
            {!isLoggedIn && showSignup &&
                <Signup
                    switch={() => {setShowSignup(false); setShowLogin(true);}}
                    close={() => {setShowSignup(false); setShowLogin(false);}}
                />}
        </div>
    )
}

export default Landing;
