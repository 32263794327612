import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import './global.css';
import Home from './Pages/Home';
import Landing from './Pages/Landing';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './Redux/store';
import { fetchMainUserData } from './Components/Api/FetchMainUserData';
import { setNeedsAuth } from './Redux/slices/authSlice';
import { CustomErrorInfo } from './interfaces';
import RawData from './Pages/RawData';
import ErrorModal from './Components/Error/ErrorModal';

function App() {
    const [error, setError] = useState<CustomErrorInfo | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [authError, setAuthError] = useState(false);

    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    useEffect(() => {
        const na: string | null = localStorage.getItem('na-x');
        if (na != "0" && na != "1") localStorage.setItem('na-x', "0");
        const needsAuth: boolean = localStorage.getItem('na-x') == "0" ? false : true;
        dispatch(setNeedsAuth(needsAuth));

        if (isLoggedIn) document.body.classList.remove('preventBgScroll');;

        if (!needsAuth && !isLoggedIn) fetchMainUserData(dispatch, setError, setIsLoading, setAuthError);
        else setIsLoading(false);
    }, [dispatch, isLoggedIn]);

    return (
        <div className="app">
            {!isLoading && isLoggedIn && <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/rawData" element={<RawData />} />
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>}
            {!isLoading && !isLoggedIn && <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>}
            {error && <ErrorModal message={error.message} action={!authError ? () => null : () => error.action()} closeModal={() => setError(null)} />}
        </div>
    );
}

export default App;
